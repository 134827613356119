var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("registration-form", {
    attrs: {
      "is-account": "",
      action: "Register Account",
      route: "register.account"
    },
    on: { success: _vm.init }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }