<template>
    <registration-form is-account
        action="Register Account"
        route="register.account"
        @success="init"/>
</template>

<script>
import { mapMutations, mapState } from 'vuex';
import RegistrationForm from '@components/auth/RegistrationForm.vue';

export default {
    name: 'RegisterPerson',

    components: { RegistrationForm },

    computed: {
        ...mapState(['user']),
        // ...mapState('auth/verification', ['mustVerifyEmail']),
    },

    methods: {
        ...mapMutations('auth', ['login']),
        ...mapMutations('layout', ['home']),
        // ...mapMutations('auth/verification', ['setEmailJustSent', 'setEmailVerified']),
        ...mapMutations(['setCsrfToken']),
        init(data) {
            this.setCsrfToken(data.csrfToken);
            this.$store.commit('auth/verification/setEmailJustSent', data.emailJustSent);
            setTimeout(() => {
                this.login();
                if (!this.mustVerifyEmail || data.emailVerified) {
                    this.home(true);
                }
            }, 500);
        },
    },
};
</script>
